




import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import DashboardView from '@/components/templates/h/DashboardView.vue'

export default defineComponent({
  components: { DashboardView },
  layout: 'sidebar',
  setup() {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    const { $router } = instance.proxy

    return {}
  },
})
